*{margin: 0px; padding: 0px;}
*, ::after, ::before {box-sizing: border-box;}
html{height: 100%;}
body{font-family: 'Roboto', sans-serif;font-size: 0.95rem; line-height: 1.35; background-color: #ffffff; padding: 0; margin: 0px; color: #333;
    height: 100%; position: relative;
}

.container{max-width: 600px; width: 100%; padding: 12px; margin-right: auto; margin-left: auto; box-sizing: border-box;}

.row {display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; margin-right: -12px; margin-left: -12px;}
.col-3, .col-9, .col-12, .col-auto, .col {position: relative; width: 100%; padding-right: 12px; padding-left: 12px;}
.col-12 {-ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%;}
.col-9 {-ms-flex: 0 0 75%; flex: 0 0 75%; max-width: 75%;}
.col-3 {-ms-flex: 0 0 25%; flex: 0 0 25%; max-width: 25%;}
.col-auto {-ms-flex: 0 0 auto; flex: 0 0 auto; width: auto; max-width: 100%;}
.col {-ms-flex-preferred-size: 0; flex-basis: 0; -ms-flex-positive: 1; flex-grow: 1; max-width: 100%;}

.mt-2, .my-2 {margin-top: .5rem!important;}
.mb-2, .my-2 {margin-bottom: .5rem!important;}
.mt-3, .my-3 {margin-top: 1rem!important;}
.mb-3, .my-3 {margin-bottom: 1rem!important;}
.mt-4, .my-4 {margin-top: 2rem!important;}
.mb-4, .my-4 {margin-bottom: 2rem!important;}
.mt-5, .my-5 {margin-top: 3rem!important;}
.mr-1{margin-right: 0.25rem!important}

.pl-0, .px-0 {padding-left: 0!important;}
.p-2 {padding: .5rem!important;}
.p-3 {padding: 1rem!important;}
.pl-0, .px-0 {padding-left: 0!important;}
.pr-0, .px-0 {padding-right: 0!important;}



p{line-height: 1.35;}

.align-self-center {align-self: center !important;}
.text-center {text-align: center!important;}
.img-fluid {max-width: 100%; height: auto;}
strong{font-weight: 500;}
.text-muted {color: #6c757d!important;}
.text-secondary{color: #D24350!important;}
.btn {display: inline-block; font-weight: 500; color: #212529; text-align: center; vertical-align: middle; cursor: pointer; text-decoration: none;
    background-color: transparent; border: 1px solid transparent; padding: 0.375rem 0.5rem; font-size: 0.85rem; line-height: 1.5;
    border-radius: 0.25rem; transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

}
.btn.btn-primary {border-color: #D24350; color: #fff; background: #D24350;}
.btn.btn-outline-primary {border-color: #D24350; color: #D24350;}
.btn.btn-outline-primary:hover{border-color: #ca1324; color: #ca1324; background-color: #fff;}
.btn.btn-outline-secondary {border-color: #5c5c5c;}
.btn.btn-outline-secondary:hover {border-color: #222; color: #222; background-color: #fff;}
.btn i{font-style: normal; width: 11px; height:auto; margin-right: 3px; display: inline-block;}
.btn i img{width: 100%; height: auto;}

.logo{max-width: 80px;}
.card{border: solid 1px rgba(0, 0, 0, 0.01); box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.15); border-radius: 10px; padding: 0.75rem;}
.gray_gradient {
background: rgb(255,255,255);
background: -moz-linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0.05) 100%);
background: -webkit-linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0.05) 100%);
background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0.05) 100%);
}
.time_card{width: 80px; min-height: 74px; background: #fff; border-radius: 6px; border: solid 1px #ccc; color: #D24350; padding: 8px;
    font-size: 0.9rem; line-height: 1.35; font-weight: 500; position: relative;}
.time_card span{color: #333;}
.time_card::after, .time_card::before{content: ''; width: 2.5px; height:8px; background: #ccc; display: block; position: absolute; top:-4px}
.time_card::before{left: 18px;}
.time_card::after{right: 18px;}
.card-textarea{width: 100%; padding: 6px 0px 15px;}
.card-textarea textarea{width: 100%; max-height: 80px; border-radius: 8px; padding: 0.5rem; font-family: 'Roboto', sans-serif;font-size: 0.95rem; line-height: 1.35;
    border: solid 1px rgba(0, 0, 0, 0.01); box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1); outline: none;
}


.upload-documents{margin: 0 0 1rem; padding: 0px; list-style: none; display: block;}
.upload-documents li{display: inline-block; margin-right: 5px; line-height: 0; font-size: 0px; position: relative;}
.upload-documents li img{width: 60px; height:60px;}
.upload-documents li.append-doc .removedDoc{position: absolute; z-index: 99; top: 8px; right: 4px; width: 12px; height: 12px; display: none; cursor: pointer;}
.upload-documents li.append-doc:hover .removedDoc{display: block;}
.upload-documents li.append-doc .removedDoc:before, .upload-documents li.append-doc .removedDoc:after{content: ''; position: relative; display: block; width: 100%; height: 0.075rem; background: #d24350;}
.upload-documents li.append-doc .removedDoc:before{transform: rotate(45deg);}
.upload-documents li.append-doc .removedDoc:after{transform: rotate(-45deg); top: -1px;}

.upload-documents li.append-more{cursor: pointer;  position: relative;}
.upload-documents li.append-more input[type=file] {font-size:0px; position: absolute; left: 0; top: 0; opacity: 0; z-index: 9; width: 100%; height: 100%; cursor: pointer;}

.btn-upload{background: #d24350; padding: 10px 36px; border: none; font-size: 0.85rem; border-radius: 6px; font-weight: 500; text-transform: uppercase; color: #fff; cursor: pointer; line-height: 1; outline: none;}
.btn-upload:hover{background: #b4101f;}

.cta_wrap{width: 100%; border: solid 1px rgba(0, 0, 0, 0.01); box-shadow: 0px 3px 4px 2px rgba(0, 0, 0, 0.1); padding-left: 12px; padding-right: 12px;}
.call_shortly{background: #efefef; padding: 10px; min-width: 300px; width: 70%; margin: 0 auto; border:solid 1px #d24350; border-radius: 12px; font-weight: 500;}
.call_shortly p{font-size: 1.1rem; line-height: 1.4; color: #666;}
.call_shortly strong{font-size: 1.1rem; text-transform: uppercase;}
.full_screen{width: 100%; position: relative; /*top: 50%; -ms-transform: translateY(-50%); transform: translateY(-50%);*/}
.full_screen .logo{margin: 0px auto 30px; max-width: 120px;}
.no_longer{border-top: solid 1px #d5d5d5; border-bottom: solid 1px #c2c2c2; padding-top: 25px; padding-bottom: 25px;}
.no_longer h5{font-size: 18px; line-height: 1.3; margin: 0px 0px 15px; padding: 0px; font-weight: 500; color: #666; text-transform: uppercase;}
.overlay{position: fixed; left: 0px; top: 0px; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.85); z-index: 99;}
.action_modal{position: absolute; background: #fff; width: 90%; max-width: 350px;  top: 50%; left: 50%; -ms-transform: translate(-50%, -50%); transform: translate(-50%, -50%); z-index: 9999; padding: 15px; border-radius: 20px;}
.action_modal .logo{margin: 0px auto 20px; max-width: 120px;}
.action_modal h5{font-size: 18px; line-height: 1.3; margin: 0px 0px 10px; padding: 0px; font-weight: 500; color: #444; text-transform: uppercase;}
.action_modal p{font-size: 16px; line-height: 1.3; margin: 0px 0px 10px; padding: 0px; font-weight: 500; color: #777;}
.action_modal p strong{color: #000;}

/* Salman's css */

.start-video-call .ant-modal-content {border-radius:20px;}
#uploadbtn:disabled {background: #bf6b73;}
.btn-upload.btn-loader { background-color: #ffffff; border: 1px solid #d24350; height: 33px; }
.btn-upload.btn-loader .spinner.red > div { width: 7px; height: 7px; }
.browser-list {list-style-type: none;margin: 0 0 10px 0;padding: 0;}
.android-list { margin-top: 20px; }


/* Hide video controls for iOS */
*::-webkit-media-controls-panel {
display: none!important;
-webkit-appearance: none;
}
/* Old shadow dom for play button */
*::-webkit-media-controls-play-button {
display: none!important;
-webkit-appearance: none;
}
/* New shadow dom for play button */
/* This one works! */
*::-webkit-media-controls-start-playback-button {
display: none!important;
-webkit-appearance: none;
}