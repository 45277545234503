.multi_party_calling {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
}
.multi_party_calling_body {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

/* Assuming the name of the Room's container is vc_multiparty_subscribers */
#vc_multiparty_subscribers {
  width: 100vw;
  /* height: calc(100vh - 50px); */
  background-color: #ddd;
  position: relative;
  height: calc(100vh - 0px);
}

#vc_multiparty_subscribers > .OT_publisher {
  top: 25px;
  right: 25px;
  position: absolute;
  /* border-radius: 10px; */
}
#vc_multiparty_subscribers > .OT_screenshare {
  top: 25px;
  left: 25px;
  position: absolute;
  /* border-radius: 10px; */
}
.multi_party_calling_footer {
    position: absolute;
    bottom: 8px;
    left: 0;
    right: 0;
    z-index: 99;
}
.control-btn img {width: 34px;}
.addparticipant-btn {
  background-color: #2279F9;border-radius: 15px;border: none;padding: 0px 5px 4px 5px;color: #ffffff;margin-left: 7px;line-height: 25px;font-size: 22px;
}

/* Vonage video elements CSS */
.video-audio-button {display: none !important;}